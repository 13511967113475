<template>
  <div v-if="token" id="theisen-payment">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs" role="tablist">
      <li role="presentation"
        :class="{active: zahlweise === 'kreditkarte'}">
        <a href="#theisen-payment-kreditkarte" role="tab" data-toggle="tab">
          <img src="./assets/card_visa.png" alt="Visa">
          <img src="./assets/card_mastercard.png" alt="Mastercard">
        </a>
      </li>
      <li role="presentation"
        :class="{active: zahlweise === 'ueberweisung'}">
        <a href="#theisen-payment-ueberweisung" role="tab" data-toggle="tab">Überweisung</a>
      </li>
      <li role="presentation"
        :class="{active: zahlweise === 'fahrer'}">
        <a href="#theisen-payment-fahrer" role="tab" data-toggle="tab">Beim Fahrer</a>
      </li>
    </ul>
    <!-- Tab panes -->
    <div class="tab-content">
      <div role="tabpanel" id="theisen-payment-kreditkarte"
        class="tab-pane" :class="{active: zahlweise === 'kreditkarte'}">
        <div v-if="error" class="alert alert-block alert-dismissible alert-danger error">
          <a class="close" data-dismiss="alert" href="#">×</a>
          Diese Zahlungsmethode steht aktuell nicht zur Verfügung
        </div>
        <p>Zahlung per Kreditkarte. Abwicklung durch Concardis GmbH, Eschborn bei Frankfurt am Main.</p>
        <p class="help-block">
          Concardis ist von der Bundesanstalt für Finanzdienstleistungsaufsicht, Graurheindorfer Straße 108 in 53117 Bonn, als E-Geld-Institut zugelassen und beaufsichtigt.
        </p>
        <button ref="paylinkButton" class="btn-primary btn icon-before paylink-modal-window"
          :data-href="paylinkHref"
          :disabled="loading || error"
          @click="paylinkListenerGuard">
          <span v-if="loading" class="glyphicon glyphicon-refresh spinning"></span>
          <span v-else class="glyphicon glyphicon-credit-card"></span>
          {{ buttonText }}
        </button>
      </div>
      <div role="tabpanel" id="theisen-payment-ueberweisung"
        class="tab-pane" :class="{active: zahlweise === 'ueberweisung'}">
        <p>Überweisen Sie den Betrag unter Angabe des Verwendungszweckes mit Ihrem Namen, Datum und Uhrzeit, sowie die Ref. der Fahrt auf folgendes Konto:</p>
        <pre>Vermittlungszentrale Theisen<br/><br/>Bankname:<br/>IBAN: DE86 3705 0198 0003 3040 45<br/>BIC: COLSDE33XXX<br/><br/>Verwendungszweck: Name, Datum und Uhrzeit Ref. {{token}}</pre>
      </div>
      <div role="tabpanel" id="theisen-payment-fahrer"
        class="tab-pane" :class="{active: zahlweise === 'fahrer'}">
        <p>Sie können Ihre Fahrt bequem in bar, mit EC- oder Kreditkarte beim Fahrer zahlen. Nicht möglich bei Abholungen vom Flughafen ohne Hinfahrt durch uns.</p>
      </div>
    </div>    
  </div>
</template>

<script>
const BUTTON_TEXT_DEFAULT = 'Jetzt zahlen'
const BUTTON_TEXT_OPEN = 'Zahlungsfenster öffnen'

class PaylinkMouseEvent extends MouseEvent {
    constructor(typeArg, mouseEventInit) {
        super(typeArg, mouseEventInit)
    }
}

import './assets/modal.min.js'

export default {
  props: {
    token: String,
    zahlweise: String,
  },
  data: () => ({
    BUTTON_TEXT_DEFAULT,
    BUTTON_TEXT_OPEN,
    legacyBuchung: {
      paylink: null,
    },
    loading: false,
    error: null,
  }),
  computed: {
    paylinkHref() {
      return this.legacyBuchung.paylink?.attributes.field_paylink_concardis_link || ""
    },
    buttonText() {
      return this.legacyBuchung.paylink
        ? this.BUTTON_TEXT_OPEN
        : this.BUTTON_TEXT_DEFAULT
    }
  },
  methods: {
    paylinkListenerGuard(e) {
      if (!(e instanceof PaylinkMouseEvent)) {
        // Stops paylinkModal click listener 
        e.stopImmediatePropagation()
        Promise.resolve()
          .then(() => {
            if (!this.legacyBuchung.paylink) {
              this.loading = true
              return Promise.resolve()
                .then(this.createPaylink)
                .then(this.loadLegacyBuchung)
                .finally(() => this.loading = false)
            }
          })
          .then(() => this.$nextTick(this.dispatchPaylinkMouseEvent))
          .catch(() => this.error = true)
      }
    },
    dispatchPaylinkMouseEvent() {
      if (!this.paylinkHref) return;
      // Toggle modal
      this.$refs.paylinkButton.dispatchEvent(new PaylinkMouseEvent('click'))
    },
    createPaylink () {
      return fetch(`${process.env.VUE_APP_API_URL}${this.token}/paylink/`, {
        method: "POST"
      }).then(response => {
        if (!response.ok) {
          throw new Error(`API Error: Respone status ${response.status}`)
        }
      })
    },
    loadLegacyBuchung() {
      return fetch(`${process.env.VUE_APP_API_URL}${this.token}`)
        .then(async response => {
          if (response.ok) {
            var paylink = null
            const doc = await response.json()
            try {
              const paylinkId = doc.data.relationships.paylink.data?.id
              if (paylinkId && Array.isArray(doc.included)) {
                paylink = doc.included.find(e => e.id === paylinkId) || null
              }
            } catch (err) {
              throw new Error('API Error: ' + err.message)
            }
            this.legacyBuchung.paylink = paylink
          } else {
            throw new Error(`API Error: Respone status ${response.status}`)
          }
        })
    }
  },
  created() {
    if (!this.token) return;
    this.loading = true
    this.loadLegacyBuchung()
      .catch(() => this.error = true)
      .finally(() => this.loading = false)
  },
  mounted() {
    /* global jQuery */    
    jQuery(".paylink-modal-window").paylinkModal();
  }
}

</script>

<style>
#theisen-payment {
  margin-top: 20px;
}
#theisen-payment .nav-tabs li a img{
  height: 1.25em;
  width: auto;
  padding: 0px 4px
}
.glyphicon.spinning {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}
</style>
