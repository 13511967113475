import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  const elem = document.getElementById(process.env.VUE_APP_MOUNT_ID)
  if (elem) {
    new Vue({
      render: h => h(App, {
        props: elem.dataset
      }),
    }).$mount(elem)
  }
});

